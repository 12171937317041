import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep10---ceora-ford-from-digital-marketing-to-software-engineering"
    }}>{`Ep10 - Ceora Ford: From digital marketing to software engineering`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/956435800&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep10-ceora-ford/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/S3suNQP3oIY">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`On this episode, Ceora shares her journey from digital marketing to becoming a software engineer. How a Udacity scholarship helped her have a clear path to take in tech.`}</p>
    <p>{`We talk about learning in public and how important it was for her, how to grow your Twitter following and about how choosing the right first language can help you progress in your journey.`}</p>
    <p>{`We also touch topics such as productivity and time management. Ceora shares how her obligations motivate her to keep producing content.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/ceora-ford.jpg" alt="Ceora Ford" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Ceora Ford</p>
        <p>Software Engineer</p>
        <p>
            <a href="https://www.ceoraford.com/"> Website</a> | <a href="https://twitter.com/ceeoreo_"> Twitter</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "courses"
    }}>{`Courses`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/cloud-dev-ops-nanodegree--nd9991"
        }}>{`Udacity - Cloud Dev Ops Engineer`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      